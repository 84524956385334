<template>
  <nav ref="navMenu" class="sub-navigation" :class="{ 'is-open': isMenuOpen }">
    <div class="container">
      <NavbarSubNavigationItem
        v-for="item in navItems"
        :key="item"
        :nav-item="item"
      />
      <NavbarLanguage
        v-if="host === 'promopromo'"
        class="language-selector hide-medium-up"
      />
    </div>
  </nav>
</template>

<script setup lang="ts">
const props = defineProps<{ isMenuOpen: boolean }>();
const emits = defineEmits(["close"]);

const host = useCurrentHost();

// Close the menu on click outside
const navMenu = ref<HTMLElement | null>(null);
onClickOutside(navMenu, () => {
  if (props.isMenuOpen) emits("close");
});

const usePhaseTwoFeatures = useFeatureFlag(FeatureFlag.PHASE_TWO);

const navItems = computed(() => {
  // If using phase two features, show all menu items
  if (usePhaseTwoFeatures.value) {
    return [
      "new",
      "categories",
      "shops",
      "offers",
      "brands",
      "cities",
      "blog",
      "app",
    ];
  }

  // If not, show all items except for cities
  return ["new", "categories", "shops", "offers", "brands", "blog", "app"];
});
</script>

<style lang="scss" scoped>
// Shared styles
.sub-navigation {
  background-color: $light-100;
  .container {
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

// Mobile styles (small screens)
@include for-small-screen {
  .sub-navigation {
    position: absolute;
    background-color: var(--primary);
    width: 100%;
    top: 100%;

    display: none;
    &.is-open {
      display: flex;
      z-index: 2;
    }

    .container {
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5rem;
    }
  }
}

@include for-medium-screen-up {
  .sub-navigation {
    border-bottom: 1px solid $light-85;

    .container {
      align-items: center;
      gap: 2.25rem;
    }
  }
}
</style>
