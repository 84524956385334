<template>
  <NuxtLink
    :to="
      localePath({
        name: 'search-searchQuery-type-referenceId',
        params: {
          searchQuery: item.name,
          referenceId: item.referenceId,
          type: enumToFriendly(item.type),
        },
        query: {
          q: query,
        },
      })
    "
    class="search-autocomplete-item"
    :class="{ selected: isSelected }"
    tabindex="0"
  >
    <i18n-t
      v-if="item.type === SearchSuggestionType.OfferCategory"
      keypath="search.autocomplete_type_OFFER"
      ><span>
        {{ name[0] }}<strong>{{ name[1] }}</strong
        >{{ name[2] }}
      </span>
    </i18n-t>
    <template v-else>
      {{ name[0] }}<strong>{{ name[1] }}</strong
      >{{ name[2] }}
    </template>
  </NuxtLink>
</template>

<script setup lang="ts">
import {
  SearchSuggestionType,
  type SearchSuggestionFragment,
} from "~/graphql/generated";

const props = defineProps<{
  item: SearchSuggestionFragment;
  isSelected?: boolean;
  query: string;
}>();

const localePath = useLocalePath();

// Returns an array with three items
// 0: The part of the name before the search query
// 1: The search query (highlighted)
// 2: The part of the name after the search query
const name = computed(() => {
  const name = props.item.name;
  const query = props.query.trim();

  const queryIndex = name.toLowerCase().indexOf(query.toLowerCase());

  if (queryIndex === -1) return [name];

  const beforeQuery = name.slice(0, queryIndex);
  const queryPart = name.slice(queryIndex, queryIndex + query.length);
  const afterQuery = name.slice(queryIndex + query.length);

  return [beforeQuery, queryPart, afterQuery];
});
</script>

<style lang="scss" scoped>
.search-autocomplete-item {
  display: block;
  padding: 0.75rem;
  transition: all 0.2s ease-in-out;

  &.selected,
  &:hover {
    background-color: $light-85;
  }
}
</style>
